import React from 'react'
import image from '../assets/illustrations/calling_back.svg'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Thanks = () => (
  <Layout>
    <SEO title="Page two" />
    <h2 className="font-header text-3xl text-center mt-4">Merci, je vous rappelle au plus vite !</h2>
    <img src={image} alt="image de remerciement" className="mt-12 w-1/2 mx-auto" />
  </Layout>
)

export default Thanks
